.VD { display: inline; }
.VM { display: none; }

.ContainerMenu {
    position: absolute;
    width: 280px;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 2px 3px #00000048;
    transition: height 0.5s ease, opacity 0.2s ease, margin 0.5s ease;
    overflow: hidden;
    z-index: 100;
}

.BoxMenu {
    padding: 13px 5px 13px 17px;
}

.IconCerrarMenu {
    cursor: pointer;
    max-height: 22px;
    margin-bottom: 16px;
    display: block;
}

.BoxItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.BoxItems a {
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    width: 100%;
}

.BoxItems img {
    margin-right: 6px;
    max-height: 37px;
}

.BoxItems label {
    cursor: pointer;
    margin-bottom: 6px;
    letter-spacing: 0px;
    color: #810314;
    text-shadow: 0px 1px 2px #0000004D;
    text-transform: uppercase;
    font-size: 16px;
    font-family: TradeGothic-LTBold;
}

.IconTitEspecias, .IconTitPromociones, .IconTitQuienesSomos {
    max-height: 45px;
}

.IconTitEspecias {
    width: 181px;
    margin-bottom: 9px;
}

.IconTitPromociones {
    width: 235px;
    margin-top: 15px;
}

.IconTitQuienesSomos {
    width: 235px;
    margin-bottom: 21px;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .VD { display: none; }
    .VM { display: inline; }

    .ContainerMenu {
        width: 100%;
        background: #810314 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #000000B2;
        opacity: 1;
    }

    .IconCerrarMenu {
        margin-right: 15px;
        display: initial;
    }

    .BoxItems a {
        align-items: center;
    }

    .BoxItems img {
        width: 19px;
        max-height: 49px;
    }

    .BoxItems label {
        color: #FFFFFF;
        font-size: 22px;
    }

    .IconTitEspecias, .IconTitPromociones, .IconTitQuienesSomos {
        max-height: 48px;
    }
    
    .IconTitEspecias {
        width: 176px;
        margin-bottom: 21px;
    }
    
    .IconTitPromociones {
        width: 229px;
        margin-top: 16px;
    }

    .IconTitQuienesSomos {
        width: 229px;
    }
}