.FooterDesk {
    display: inline;
}

.FooterMobile {
    display: none;
}

.Container {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: -30px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px -3px 6px #00000029;

    font-family: Avenir-Medium;
    letter-spacing: 0px;
    color: #810314;
    text-transform: uppercase;
}

a{
    cursor: pointer; 
}

.Box1 {
    height: 100%;
    padding: 0 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-evenly; /* space-between */
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .FooterDesk {
        display: none;
    }
    
    .FooterMobile {
        display: inline;
    }

    .Container {
        height: 54px;
        bottom: -54px;
        background: #810314 0% 0% no-repeat padding-box;
        box-shadow: 0px -3px 6px #00000029;

        color: #FFFFFF;
    }

    .Box1, .Box2 {
        height: 50%;
        padding: 0 11px;
    }

    .Box1 {
        font-size: 12px;
    }

    .Box2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
    }
}