.HeaderDesk { display: inline; z-index: 5; }
.HeaderMobile { display: none; }

.Container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 69px;
    margin-top: 17px;
    background: transparent;
    z-index: 3;
}

.BoxLogos, .Box_LogoSams {
    width: 48%;
    float: left;
    height: 100%;

    display: flex;
}

.BoxLogos {
    padding-left: 2%;
    justify-content: flex-start;
}

.Box_LogoSams {
    justify-content: flex-end;
    align-items: flex-start;
}

.Box1  {
    cursor: pointer;
    margin-right: 22px;
    max-height: 25px;
    padding-top: 13px;
}

.Box_LogoMembers {
    display: flex;
    align-items: flex-start;
}

.Box_LogoMembers img {
    margin-right: 23px;
    width: 75%;
    max-width: 550px;
}

.Box_LogoSams img {
    min-height: 34px;
    max-width: 275px;
}

@media screen and (max-width: 687px), (max-height: 325px) {
    .HeaderDesk { display: none; }
    .HeaderMobile { display: inline; }

    .Container {
        width: 100%;
        height: auto;
        margin-top: 10px;
    }

    .BoxMenu {
        float: left;
        height: 100%;
        width: 14%;
        padding-left: 4%;
    }

    .BoxMenu img {
        max-height: 20px;
    }

    .BoxLogos {
        width: 78%;
        padding-right: 4%;
        padding-left: 0;
        justify-content: flex-start;
        align-items: center;
    }

    .Box_LogoMembers {
        margin-right: 28px;
    }

    .Box_LogoMembers img {
        height: 84px;
    }

    .Box_LogoSams {
        width: 100%;
    }

    .Box_LogoSams img {
        height: 42px;
    }
}