.CssLayoutContainer {
    min-height: 95vh;
    min-width: 100%;
    position: relative;
}

body {
    margin: 0;
    padding-bottom: 30px;
}


@media screen and (max-width: 687px), (max-height: 325px) {
    body {
        padding-bottom: 54px;
    }

    .modal-video-close-btn {
        right: 10px;
        width: 20px;
    }
}


@font-face {
    font-family: Avenir-Black;
    src: url(./fonts/Avenir/Avenir-Black.woff2);
}

@font-face {
    font-family: Avenir-Light;
    src: url(./fonts/Avenir/Avenir-Light.woff2);
}

@font-face {
    font-family: Avenir-Medium;
    src: url(./fonts/Avenir/Avenir-Medium.woff2);
}

@font-face {
    font-family: AvenirLTStd-Book;
    src: url(./fonts/Avenir/AvenirLTStd-Book.woff2);
}

@font-face {
    font-family: AvenirLTStd-Medium;
    src: url(./fonts/Avenir/AvenirLTStd-Medium.woff2);
}

@font-face {
    font-family: AvenirLTStd-Roman;
    src: url(./fonts/Avenir/AvenirLTStd-Roman.woff2);
}

@font-face {
    font-family: Cocogoose-Inline;
    src: url(./fonts/Cocogoose/Cocogoose-ProInlineTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProLetterpress;
    src: url(./fonts/Cocogoose/Cocogoose-ProLetterpressTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProLightItalic;
    src: url(./fonts/Cocogoose/Cocogoose-ProLightItalicTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProLight;
    src: url(./fonts/Cocogoose/Cocogoose-ProLightTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProOutlined;
    src: url(./fonts/Cocogoose/Cocogoose-ProOutlinedTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProRegularItalic;
    src: url(./fonts/Cocogoose/Cocogoose-ProRegularItalicTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProRegular;
    src: url(./fonts/Cocogoose/Cocogoose-ProRegularTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProSemiLightItalic;
    src: url(./fonts/Cocogoose/Cocogoose-ProSemiLightItalicTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProSemiLight;
    src: url(./fonts/Cocogoose/Cocogoose-ProSemiLightTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProThinItalic;
    src: url(./fonts/Cocogoose/Cocogoose-ProThinItalicTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProThin;
    src: url(./fonts/Cocogoose/Cocogoose-ProThinTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProUltralightItalic;
    src: url(./fonts/Cocogoose/Cocogoose-ProUltralightItalicTrial.ttf);
}

@font-face {
    font-family: Cocogoose-ProUltralight;
    src: url(./fonts/Cocogoose/Cocogoose-ProUltralightTrial.ttf);
}

@font-face {
    font-family: TradeGothic-LT;
    src: url(./fonts/TradeGothic/TradeGothic-LT.ttf);
}

@font-face {
    font-family: TradeGothic-LTBold;
    src: url(./fonts/TradeGothic/TradeGothic-LTBold.ttf);
}

@font-face {
    font-family: TradeGothic-LTBoldOblique;
    src: url(./fonts/TradeGothic/TradeGothic-LTBoldOblique.ttf);
}

@font-face {
    font-family: TradeGothic-LTLight;
    src: url(./fonts/TradeGothic/TradeGothic-LTLight.ttf);
}

@font-face {
    font-family: TradeGothic-LTOblique;
    src: url(./fonts/TradeGothic/TradeGothic-LTOblique.ttf);
}